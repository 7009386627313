import React from "react";
import {Link, navigate} from "gatsby";
import colors from "../lib/colors";
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import Cookies from "cookies-js";
import fetch from "../lib/fetch";
import {XForm, rules, SubmitButton} from "../lib/forms";
import {PlainButton, rawButtonStyle, TransparentButton} from "../components/Button";
import GhostButton from "../components/ui/GhostButton";
import {PasswordWithScoreWithLabel} from "../components/PasswordWithScore";
import {useAppInfo} from "../features/AppInfo";
import DefaultLayout from "../components/DefaultLayout";
import {
  useCodeUpdaterOnMount,
  useSourceInfo,
  clearCodeData,
} from "../features/affiliate/manage-cookies";
import {Text} from "../components/ui/Text";
import Hero from "../components/bricks/Hero";
import Col from "../components/ui/Col";
import Row from "../components/ui/Row";
import {useNeoBag, useNeoField} from "../lib/forms/neo-form";
import Push from "../components/ui/Push";
import {Spinner} from "../components/ui/Spinner";
import useCacheAndDebounce from "../lib/useCacheAndDebounce";
import {InputWithLabel} from "../components/Input";
import {WithLabel} from "../lib/forms/FieldWithLabel";
import {trackEvent, trackPageview} from "../lib/tracking";
import ThemeBox from "../components/bricks/ThemeBox";
import {themeDark} from "../page-styles/landingpage.css";
import {Box, XCol, XRow} from "../components/xui/Box";
import {useAuthFlow} from "../features/auth/oauth-flow";
import {ThemedButton} from "../components/xui/ThemedButton";
import Icon from "../components/Icon";
import {OrLine} from "./login";

const Summary = styled("div")({
  fontSize: "1.5rem",
  lineHeight: 1.5,
  textAlign: "left",
  " b": {color: colors.white},
});

const StepButton = styled(props => (props.to ? <Link {...props} /> : <button {...props} />), {
  shouldForwardProp: prop => prop !== "active",
})(rawButtonStyle, ({active, to}) => ({
  width: "0.8rem",
  height: "0.8rem",
  borderRadius: "50%",
  padding: 0,
  margin: "0 0.7rem",
  transitionProperty: "opacity, border-color, background-color",
  cursor: to ? "pointer" : "default",
  border: `0.05rem solid ${active ? colors.link : colors.fade(colors.white, 0.4)}`,
  backgroundColor: active ? colors.link : "transparent",
  opacity: to ? 1 : 0.5,
  ":hover": {
    backgroundColor: to
      ? active
        ? colors.linkHover
        : colors.fade(colors.white, 0.1)
      : "transparent",
  },
  ":focus": {
    outline: "none",
    boxShadow: `0 0 0 0.2rem ${
      active ? colors.fade(colors.linkHover, 0.3) : colors.fade(colors.white, 0.2)
    }`,
  },
}));

const nonUserSteps = [{idx: 0}, {idx: 1}, {idx: 2}];
const userSteps = [nonUserSteps[0], nonUserSteps[2]];

const Stepper = ({user, currStepIdx}) => {
  const steps = user ? userSteps : nonUserSteps;

  return (
    <XRow justify="center">
      {steps.map(step => (
        <StepButton
          key={step.idx}
          to={step.idx <= currStepIdx ? "/create-account/" : null}
          state={step.idx <= currStepIdx ? {step: step.idx} : null}
          active={step.idx === currStepIdx}
        />
      ))}
    </XRow>
  );
};

const isProbablyCustomEmail = email =>
  !/((mail|@gmx|@outlook|@o2|@icloud|@msn|@web|@aol|@t-online|@yahoo|@posteo|@live).\w{2,3})|\.edu$/.test(
    email
  );

const notifyTrackers = email =>
  new Promise(res => {
    trackPageview("/create-account/done");

    if (email && isProbablyCustomEmail(email)) {
      trackPageview("/create-account/done-with-custom-email");
    }
    trackEvent({label: "create-account", action: "click", category: "Outbound", cb: res});
  });

const InnerLayout = ({
  title,
  subline,
  children,
  user,
  stepIdx,
  width = "sm",
  sp,
  noPaddingBottom,
}) => (
  <XCol minHeight="fullScreen">
    <Hero size="sm" title={title} subline={subline} noNav noPaddingBottom={noPaddingBottom} />
    <ThemeBox theme={themeDark} py={6} width={width} sp={8} fillParent>
      <XCol fillParent justify="center" sp={sp}>
        {children}
      </XCol>
      <Stepper user={user} currStepIdx={stepIdx} />
    </ThemeBox>
  </XCol>
);

const NextButton = () => {
  const formBag = useNeoBag();
  return (
    <SubmitButton as={PlainButton} formBag={formBag}>
      Next
    </SubmitButton>
  );
};

const emailCheck = email => {
  if (!email) return Promise.resolve(true);
  return fetch(`/services/check-email-unique?email=${encodeURIComponent(email)}`).then(
    data => data.ok,
    e => {
      console.error(e);
      return true;
    }
  );
};

const subdomainCheck = value => {
  if (!value) return Promise.resolve(true);
  return fetch(`/services/check-valid-subdomain?subdomain=${encodeURIComponent(value)}`).then(
    data => data.ok,
    e => {
      console.error(e);
      return true;
    }
  );
};

const slugify = input =>
  input
    .replace(/[^A-Za-z0-9]+/g, " ")
    .trim()
    .replace(/ /g, "-")
    .toLowerCase();
const genSubdomain = input => {
  let slug = slugify(input);
  if (slug.length === 0) {
    if (input.length > 0) {
      return `team-${Math.random()
        .toString()
        .slice(2, 6)}`;
    } else {
      return "";
    }
  } else if (slug.length < 3) {
    slug = `${slug}${Math.random()
      .toString()
      .slice(2, 6)}`;
  }
  return slug;
};

const getMoreUnique = input =>
  `${slugify(input)}${Math.random()
    .toString()
    .slice(2, 6)}`;

const uniqSubdmainErrorMsg = "Couldn't generate unique subdomain";

const SubdomainInput = React.forwardRef(
  ({value, onChange, generateSubdomain, name, ...rest}, ref) => {
    const handleChange = name => {
      onChange({name, subdomain: generateSubdomain ? genSubdomain(name) : value.subdomain});
    };
    const onChangeRef = React.useRef(onChange);
    React.useEffect(() => {
      onChangeRef.current = onChange;
    }, [onChange]);

    const [, meta] = useNeoField(name);
    const hasUniqError = meta.errors.some(msg => msg === uniqSubdmainErrorMsg);
    React.useEffect(() => {
      if (hasUniqError) {
        onChangeRef.current({name: value.name, subdomain: getMoreUnique(value.name)});
      }
    }, [hasUniqError, value]);

    return <InputWithLabel value={value.name} onChange={handleChange} {...rest} />;
  }
);

const OrgStep = ({user, orgData, setOrgData, location, stepIdx}) => {
  const subDomainChecker = useCacheAndDebounce(subdomainCheck);
  const [manualSubdomain, setManualSubdomain] = React.useState(false);
  const [values, setValues] = React.useState({
    combo: {name: orgData.accountName, subdomain: orgData.subdomain},
    subdomain: orgData.subdomain,
  });

  const handleSubmit = () => {
    setOrgData({
      accountName: values.combo.name,
      subdomain: manualSubdomain ? values.subdomain : values.combo.subdomain,
    });
    navigate(location.pathname, {state: {step: user ? 2 : 1}});
  };

  return (
    <InnerLayout
      title="Hi there!"
      subline={
        user
          ? `Hello${
              user.name ? ` ${user.name}` : ""
            }! You're about to create a new account on Codecks. You can use your existing credentials to log into this new account.`
          : "Creating an account will only take a moment. No credit card required."
      }
      stepIdx={stepIdx}
    >
      <Col
        as={XForm}
        values={values}
        onSubmit={handleSubmit}
        onChange={setValues}
        rules={{
          combo: [
            [({name}) => name.length > 0, "is required"],
            [({name}) => name.length <= 50, "at most 50 characters allowed"],
            ...(manualSubdomain
              ? []
              : [
                  [({subdomain}) => subdomain.length >= 3, "Couldn't generate subdomain yet"],
                  [({subdomain}) => subDomainChecker(subdomain), uniqSubdmainErrorMsg],
                ]),
          ],
          ...(manualSubdomain
            ? {
                subdomain: [
                  rules.isRequired,
                  rules.minLength(3),
                  [
                    val => /^(|[a-z0-9][a-z0-9-]+[a-z0-9])$/.test(val),
                    "contains invalid combination of characters",
                  ],
                  [subDomainChecker, "needs to be a unique subdomain"],
                ],
              }
            : {}),
        }}
        sp={5}
      >
        <XForm.Field
          name="combo"
          as={SubdomainInput}
          generateSubdomain={!manualSubdomain}
          label="Organisation"
          hint="This is the name of your group, organisation, mega-conglomerate."
          autoFocus
          placeholder="e.g. Weyland"
        />
        {manualSubdomain ? (
          <XForm.Field
            name="subdomain"
            label="Your URL"
            placeholder="weyland"
            hint="Subdomain may consist of lowercase letters, numbers and '-'."
            postfix={<Text color="gray500">.codecks.io</Text>}
          />
        ) : (
          <WithLabel label="Your Url">
            <Row align="baseline" sp={1}>
              <Row align="baseline">
                <Text size={4} preset="bold" color={values.combo.subdomain ? "white" : "gray400"}>
                  {values.combo.subdomain || "[your-name]"}
                </Text>
                <Text size={4} color="gray400">
                  .codecks.io
                </Text>
              </Row>
              <Push />
              {values.combo.subdomain && (
                <TransparentButton
                  color="dim"
                  size="small"
                  onClick={() => {
                    setValues({...values, subdomain: values.combo.subdomain});
                    setManualSubdomain(true);
                  }}
                >
                  customize
                </TransparentButton>
              )}
            </Row>
          </WithLabel>
        )}
        <Row>
          <Push />
          <NextButton />
        </Row>
      </Col>
    </InnerLayout>
  );
};

export const SignupProviderButton = ({provider, children, onDone}) => {
  const buttonProps = useAuthFlow({
    provider,
    intent: "signup",
    onDone,
  });
  return (
    <ThemedButton size="sm" {...buttonProps}>
      {children}
    </ThemedButton>
  );
};

const AuthProviders = ({onDone}) => {
  return (
    <Row sp={2} align="center" justify="center">
      <Box bold size="sm" color="secondary">
        Sign up with
      </Box>
      <SignupProviderButton provider="discord" onDone={onDone}>
        <Row sp={1} align="center">
          <Icon.Discord />
          <div>Discord</div>
        </Row>
      </SignupProviderButton>
      <SignupProviderButton provider="google" onDone={onDone}>
        <Row sp={1} align="center">
          <Icon.Google />
          <div>Google</div>
        </Row>
      </SignupProviderButton>
    </Row>
  );
};

const UserStep = ({userData, setUserData, location, stepIdx}) => (
  <InnerLayout
    title="Create your user account!"
    subline="Your user account can be used for multiple organisations."
    stepIdx={stepIdx}
    sp={5}
    noPaddingBottom
  >
    <AuthProviders onDone={() => navigate(location.pathname, {state: {step: 2}})} />
    <OrLine>Or, use your email address</OrLine>
    <Col
      as={XForm}
      sp={4}
      px={2}
      values={userData}
      onSubmit={() => navigate(location.pathname, {state: {step: 2}})}
      onChange={setUserData}
      rules={{
        email: [
          rules.isRequired,
          rules.isEmail,
          [
            useCacheAndDebounce(emailCheck),
            "You have a Codecks account already. If you want to create a new organziation with this user, please log in and come back to this URL!",
          ],
        ],
        username: [
          rules.isRequired,
          [
            val => /^(|[A-Za-z0-9][\w-]*)$/.test(val),
            "may only consist of letters, numbers, '-' and '_'",
          ],
        ],
        password: [rules.isRequired, rules.minLength(6)],
      }}
    >
      <XForm.Field
        type="email"
        name="email"
        label="Your email"
        placeholder="e.g. liz.mills@weyland-consortium.com"
        autoFocus
      />
      <XForm.Field
        name="username"
        label="Name"
        placeholder="e.g. liz"
        hint="This will be used a lot by your co-workers, so pick something easily recognisable as you. Something as plain as 'Alex' or 'Laura' will probably be fine."
      />
      <XForm.Field
        as={PasswordWithScoreWithLabel}
        name="password"
        label="Password"
        autoComplete="new-password"
      />
      <Row align="center">
        <GhostButton color="white" size="sm" to={location.pathname} state={{step: 0}}>
          Back
        </GhostButton>
        <Push />
        <NextButton />
      </Row>
    </Col>
  </InnerLayout>
);

const useGaClientId = () => {
  const [gaClientId, setGaClientId] = React.useState(null);
  React.useEffect(() => {
    if (window && window.gtag && process.env.GATSBY_GA_ID) {
      window.gtag("get", process.env.GATSBY_GA_ID, "client_id", client_id => {
        setGaClientId(client_id);
      });
    }
  }, []);
  return gaClientId;
};

const ConfirmStep = ({orgData, userData, user, location, stepIdx, sourceInfo}) => {
  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState(null);
  const gaClientId = useGaClientId();

  React.useEffect(() => {
    if (orgData.accountName && orgData.subdomain) {
      if (user) return;
      if (userData.email && userData.username && userData.password) return;
    }
    navigate(location.pathname, {step: 0});
  }, [orgData, userData, user, location]);

  const handleGo = () => {
    if (submitting) return;
    setSubmitting(true);
    if (user) {
      return fetch(`/services/account-creation-exisiting`, {
        method: "POST",
        headers: {"X-Auth-Token": Cookies.get("auth-token")},
        body: JSON.stringify({
          accountName: orgData.accountName,
          subdomain: orgData.subdomain,
          source: {...sourceInfo, gaClientId, existing_user_id: user.id},
        }),
      })
        .then(data => {
          return notifyTrackers().then(() => data);
        })
        .then(
          json => {
            clearCodeData();
            window.location = `https://${orgData.subdomain}${process.env.GATSBY_COOKIE_DOMAIN}/decks`;
          },
          e => {
            if (typeof e === "string") {
              try {
                e = JSON.parse(e);
              } catch (e2) {}
            }
            const error =
              (e && e.message && (e.message.error || e.message.toString())) || e.toString();
            setSubmitting(false);
            setError(error);
          }
        );
    } else {
      return fetch(`/services/account-creation`, {
        method: "POST",
        body: JSON.stringify({...orgData, ...userData, source: {...sourceInfo, gaClientId}}),
      })
        .then(data => {
          return notifyTrackers(userData.email).then(() => data);
        })
        .then(
          json => {
            clearCodeData();
            window.location = `https://${orgData.subdomain}${process.env.GATSBY_COOKIE_DOMAIN}/decks`;
          },
          e => {
            if (typeof e === "string") {
              try {
                e = JSON.parse(e);
              } catch (e2) {}
            }
            const error =
              (e && e.message && (e.message.error || e.message.toString())) || e.toString();
            setSubmitting(false);
            setError(error);
          }
        );
    }
  };

  return (
    <InnerLayout
      title="3... 2... 1... go!"
      subline="Let's recap the information you entered."
      stepIdx={stepIdx}
    >
      <Col sp={4} css={css({textAlign: "center", color: colors.fade(colors.white, 0.7)})}>
        <Summary>
          You're going to create an account for <b>{orgData.accountName}</b>. Your organization URL
          will be <b>{orgData.subdomain}.codecks.io</b>.
          {!user && (
            <span>
              {" "}
              Your username is <b>{userData.username}</b> and your email is{" "}
              <b css={css({fontSize: "1rem"})}>{userData.email}</b>.
            </span>
          )}
        </Summary>
        <div css={css({fontSize: "0.8rem"})}>
          If all this information is correct, click that big button below. Otherwise hit <b>back</b>{" "}
          to make changes.
        </div>
        <Row align="center">
          <GhostButton color="white" size="sm" to={location.pathname} state={{step: user ? 0 : 1}}>
            Back
          </GhostButton>
          <Push />
          <PlainButton onClick={handleGo} disabled={submitting} style={{position: "relative"}}>
            <div style={{opacity: submitting ? 0 : 1}}>Go!</div>
            {submitting && <Spinner size={20} />}
          </PlainButton>
        </Row>
        {error && (
          <Col
            sp={1}
            css={css({
              fontSize: "0.8rem",
              color: colors.dangerTextOnDark,
              marginBottom: "1rem",
              textAlign: "center",
            })}
          >
            <div>Oh no! Something went wrong:</div>
            <div>
              <b>{error}</b>
            </div>
          </Col>
        )}
      </Col>
    </InnerLayout>
  );
};

const stepToComp = {0: OrgStep, 1: UserStep, 2: ConfirmStep};

const CreateAccount = ({location}) => {
  useCodeUpdaterOnMount();
  const sourceInfo = useSourceInfo();
  const appInfo = useAppInfo();
  const [currStep, setCurrStep] = React.useState(0);

  const {step: locStep, email} = location.state || {};

  React.useEffect(() => {
    setCurrStep(locStep || 0);
  }, [locStep]);

  const [orgData, setOrgData] = React.useState({accountName: "", subdomain: ""});
  const [userData, setUserData] = React.useState({email: email || "", username: "", password: ""});
  const StepComp = stepToComp[currStep] || OrgStep;

  const isFirstRender = React.useRef(true);

  React.useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    } else {
      trackPageview(`/create-account${currStep > 0 ? `/${currStep + 1}` : ""}`);
    }
  }, [currStep]);

  return (
    <DefaultLayout title="Create Account" footer={null} location={location}>
      <StepComp
        user={appInfo.user}
        orgData={orgData}
        userData={userData}
        setOrgData={setOrgData}
        setUserData={setUserData}
        location={location}
        stepIdx={currStep}
        sourceInfo={sourceInfo}
      />
    </DefaultLayout>
  );
};

export default CreateAccount;
